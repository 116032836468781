import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, IconButton, Paper, Stack } from '@mui/material';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

import { isNullOrEmpty } from '../../Tools/StringTools';
import { setConsularService, setCurrentStep, setDefaultValues, setStepClientData, setStepPersonalData, setValues } from '../../_redux/CreatePassportReducer'
import Step1 from '../../components/passport/StepClientData';
import Step2 from '../../components/passport/StepPersonalData';
import Step3 from '../../components/passport/StepGeneralData';
import Step4 from '../../components/passport/StepLaboralData';
import Step5 from '../../components/passport/StepReferenceCuba';
import Step6 from '../../components/passport/StepPhotoSignature';
import Step7 from '../../components/passport/StepPayment';
import { paymentType, servicioConsular } from '../../_helpers/enums';
import { passportService } from '../../_services';

const steps = [
    'Datos del cliente',
    'Datos personales',
    'Datos Generales',
    'Datos Laborales',
    'Referencia en Cuba',
    'Foto de pasaporte',
    'Métodos de Pago',
];

export default function PrimerVezPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const form = useSelector(s => s.createPassport);
    const activeStep = form.currentStep;
    const { addToast } = useToasts()
    const [completed, setCompleted] = React.useState({});
    const [saving, setSaving] = React.useState(false);

    const { stepClientData, stepPersonalData, stepGeneralData, stepLaboralData, stepReferenceCuba, stepPhotoSignature, stepPaymentData, loadingPrimerVez, loadingRenovar, loadingProrroga1, loadingProrroga2 } = form;

    React.useEffect(() => {
        setStepData();
    }, [])

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const isValid = validateStep();
        if (isValid) {
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed,
                    // find the first step that has been completed
                    steps.findIndex((step, i) => !(i in completed))
                    : activeStep + 1;
            dispatch(setCurrentStep(newActiveStep));
        }
    };

    const handleBack = () => {
        dispatch(setCurrentStep(activeStep - 1));
    };

    const handleStep = (step) => () => {
        dispatch(setCurrentStep(step));
    };

    const handleReset = () => {
        dispatch(setCurrentStep(0));
        setCompleted({});
    };

    const validateStep = () => {
        if (activeStep === 0) {
            if (Object.keys(stepClientData.touched).length === 0) {
                addToast("Debe completar los datos", {
                    appearance: 'info',
                    autoDismiss: true,
                });
                return false;
            }
            if (!stepClientData.isValid) {
                addToast("Debe validar que los campos estén correctos", {
                    appearance: 'info',
                    autoDismiss: true,
                });
                return false;
            }
        }
        else if (activeStep === 1) {
            console.log(stepPersonalData.errors)
            if (Object.keys(stepPersonalData.touched).length === 0) {
                addToast("Debe completar los datos", {
                    appearance: 'info',
                    autoDismiss: true,
                });
                return false;
            }
            if (!stepPersonalData.isValid) {
                addToast("Debe validar que los campos estén correctos", {
                    appearance: 'info',
                    autoDismiss: true,
                });
                return false;
            }
        }
        else if (activeStep === 2) {
            if (Object.keys(stepGeneralData.touched).length === 0) {
                addToast("Debe completar los datos", {
                    appearance: 'info',
                    autoDismiss: true,
                });
                return false;
            }
            if (!stepGeneralData.isValid) {
                addToast("Debe validar que los campos estén correctos", {
                    appearance: 'info',
                    autoDismiss: true,
                });
                return false;
            }
        }
        else if (activeStep === 3) {
            if (Object.keys(stepLaboralData.touched).length === 0) {
                addToast("Debe completar los datos", {
                    appearance: 'info',
                    autoDismiss: true,
                });
                return false;
            }
            if (!stepLaboralData.isValid) {
                addToast("Debe validar que los campos estén correctos", {
                    appearance: 'info',
                    autoDismiss: true,
                });
                return false;
            }
        }
        else if (activeStep === 4) {
            if (Object.keys(stepReferenceCuba.touched).length === 0) {
                addToast("Debe completar los datos", {
                    appearance: 'info',
                    autoDismiss: true,
                });
                return false;
            }
            if (!stepReferenceCuba.isValid) {
                addToast("Debe validar que los campos estén correctos", {
                    appearance: 'info',
                    autoDismiss: true,
                });
                return false;
            }
        }
        else if (activeStep === 5) {
            if (isNullOrEmpty(stepPhotoSignature.values.passportPhoto)) {
                addToast("Debe adjuntar una foto de pasaporte", {
                    appearance: 'info',
                    autoDismiss: true,
                });
                return false;
            }
            if (isNullOrEmpty(stepPhotoSignature.values.signature)) {
                addToast("Debe firmar antes de continuar", {
                    appearance: 'info',
                    autoDismiss: true,
                });
                return false;
            }
        }

        return true;
    }

    const saveSteps = () => {
        const dataPrimerVez = { ...form }
        localStorage.setItem("formPrimerVez", JSON.stringify(dataPrimerVez));
        addToast("Los datos han sido guardados", {
            appearance: "success",
            autoDismiss: true
        })
    }

    const setStepData = () => {
        const data = JSON.parse(localStorage.getItem("formPrimerVez"));
        if (data != null && form.consularService === servicioConsular.None) {
            dispatch(setValues({ ...data, currentStep: 0, loadingPrimerVez: 0, loadingRenovar: 1, loadingProrroga1: 1, loadingProrroga2: 1 }))
        }
        else {
            dispatch(setValues({ ...form, currentStep: activeStep, loadingPrimerVez: 0, loadingRenovar: 1, loadingProrroga1: 1, loadingProrroga2: 1 }))
        }
        dispatch(setConsularService(servicioConsular.PrimerVez));

    }

    const deleteStepsData = () => {
        localStorage.removeItem("formPrimerVez");
    }

    const submit = () => {
        setSaving(true);

        if (!form.stepPaymentData.values.agree) {
            addToast("Debe aceptar términos y condiciones", {
                appearance: 'info',
                autoDismiss: true,
            });
            setSaving(false);
            return false;
        }

        const photo = form.stepPhotoSignature.values.passportPhoto.split("base64,")[1]
        const signature = form.stepPhotoSignature.values.signature.split("base64,")[1]

        const data = {
            paymentCardId: form.stepPaymentData.selectedPayment.paymentCardId,
            promoCode: form.stepPaymentData.values.promoCode,
            zelleName: form.stepPaymentData.values.zelleName,
            paymentType: form.stepPaymentData.selectedPayment.type,
            total: form.stepPaymentData.values.amount,
            servicioConsular: servicioConsular.PrimerVez,
            passportPrimerVez: {
                ...form.stepClientData.values,
                country: "United States",
                father: form.stepPersonalData.values.father,
                mother: form.stepPersonalData.values.mother,
                height: form.stepPersonalData.values.height,
                // specialCharacter: "string",
                sex: form.stepPersonalData.values.gender,
                colorEyes: form.stepPersonalData.values.eyeColor,
                skinColor: form.stepPersonalData.values.skinColor,
                hairColor: form.stepPersonalData.values.hairColor,
                classificationMigration: form.stepGeneralData.values.classificationMigration,
                departureDate: form.stepGeneralData.values.departureDate,
                countryOfBirth: form.stepGeneralData.values.checkCountry === '1' ? form.stepGeneralData.values.countryOfBirth : "Cuba",
                provinceBirth: form.stepGeneralData.values.provinceBirth,
                municipalityBirth: form.stepGeneralData.values.municipalityBirth,
                ...form.stepLaboralData.values,
                ...form.stepReferenceCuba.values,
                passportPhoto: photo,
                signature: signature,
            }
        }
        if (isNullOrEmpty(data.paymentCardId)) {
            data.paymentCardId = "00000000-0000-0000-0000-000000000000";
        }

        if (data.paymentType !== paymentType.CreditCard && data.paymentType !== paymentType.Zelle) {
            addToast("Debe elegir un tipo de pago", {
                appearance: 'info',
                autoDismiss: true,
            });
            setSaving(false);
            return false;
        }

        if (data.paymentType === paymentType.CreditCard && isNullOrEmpty(data.paymentCardId)) {
            addToast("La targeta de pago no es correcta", {
                appearance: 'info',
                autoDismiss: true,
            });
            setSaving(false);
            return false;
        }

        if (data.paymentType === paymentType.Zelle && isNullOrEmpty(data.zelleName)) {
            addToast("El nombre de zelle no es correcto", {
                appearance: 'info',
                autoDismiss: true,
            });
            setSaving(false);
            return false;
        }

        passportService.createPassport(data).then(res => {
            if (res.success) {
                addToast("El pasaporte ha sido creado", {
                    appearance: 'success',
                    autoDismiss: true,
                });
                dispatch(setDefaultValues());
                deleteStepsData();
                navigate("/dashboard/passports")
            }
            else {
                addToast(res.errorMessage, {
                    appearance: 'info',
                    autoDismiss: true,
                });
            }
            setSaving(false);
        }, error => {
            console.log(error);
            setSaving(false);
            addToast("Ha ocurrido un error", {
                appearance: 'error',
                autoDismiss: true,
            });
        })
    }

    return (
        <Paper elevation={3} sx={{ p: 2, ml: 2, mr: 2 }}>
            <h3>Pasaporte por primera vez</h3>
            <Box sx={{ width: '100%', mt: 3 }}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)} />
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {allStepsCompleted() ? (
                        <div>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleReset}>Reset</Button>
                            </Box>
                        </div>
                    ) : (
                        <div>

                            <Container sx={{ pt: 3 }}>
                                <div>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <h3 className="marginBottom">{steps[activeStep]}</h3>
                                        <IconButton aria-label="delete" onClick={saveSteps}>
                                            <SaveIcon />
                                        </IconButton>
                                    </Stack>
                                    {activeStep === 0 && !loadingPrimerVez && <Step1 />}
                                    {activeStep === 1 && !loadingPrimerVez && <Step2 />}
                                    {activeStep === 2 && !loadingPrimerVez && <Step3 />}
                                    {activeStep === 3 && !loadingPrimerVez && <Step4 />}
                                    {activeStep === 4 && !loadingPrimerVez && <Step5 />}
                                    {activeStep === 5 && !loadingPrimerVez && <Step6 />}
                                    {activeStep === 6 && !loadingPrimerVez && <Step7 />}
                                </div>
                            </Container>

                            <Stack
                                sx={{ mt: 2 }}
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={2} >
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>

                                {activeStep === (totalSteps() - 1) ?
                                    (
                                        <LoadingButton
                                            loading={saving}
                                            loadingPosition="start"
                                            startIcon={<SaveIcon />}
                                            variant="contained"
                                            sx={{ mr: 1 }}
                                            onClick={submit}
                                        >
                                            Pagar
                                        </LoadingButton>
                                    ) :
                                    (<Button variant="outlined" onClick={handleNext} sx={{ mr: 1 }}>
                                        Next
                                    </Button>)
                                }
                            </Stack>
                        </div>
                    )}
                </div>
            </Box>
        </Paper>

    );
}



import { createBrowserHistory } from 'history';
import { useNavigate } from 'react-router';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';


// ----------------------------------------------------------------------

export const History = {
  navigate: null,
  push: (page, ...rest) => History.navigate(page, ...rest),
};

export default function App() {

  History.navigate = useNavigate();

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
    </ThemeConfig>
  );
}


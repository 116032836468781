import '../../_styles/StepPassportPayment.css'
import { Button, Container, Divider, Grid, TextField, Link } from "@mui/material";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import NumberFormat from 'react-number-format';

import { passportService, paymentCardService, promoCodeService, authenticationService } from '../../_services'
import { paymentType, rateType, servicioConsular } from '../../_helpers/enums';
import { setStepPaymentData } from '../../_redux/CreatePassportReducer';
import { isNullOrEmpty } from '../../Tools/StringTools';



export default function StepPayment() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const consularService = useSelector(s => s.createPassport.consularService);
    const stepPayment = useSelector(s => s.createPassport.stepPaymentData);
    const { selectedPayment, values } = stepPayment;
    const { addToast } = useToasts();
    const [paymentCards, setPaymentCards] = useState([]);
    const currentUser = authenticationService.currentUserValue;

    useEffect(() => {
        if (currentUser) {
            getPaymentCards();
        }
        else {
            let url = "";
            switch (consularService) {
                case servicioConsular.PrimerVez:
                    url = "/dashboard/primervez"
                    break;
                case servicioConsular.Prorroga1:
                    url = "/dashboard/prorrogasimple"
                    break;
                case servicioConsular.Prorroga2:
                    url = "/dashboard/prorrogadoble"
                    break;
                case servicioConsular.Renovacion:
                    url = "/dashboard/renovar"
                    break;
                default:
                    break;
            }
            localStorage.setItem("url_callback", url);
            navigate("/login");
        }
    }, []);

    useEffect(() => {
        calculate();
    }, [selectedPayment, values?.applyPromoCode]);

    const getConfiguration = async () => {
        const config = await passportService.getConfiguration();
        return config.object;
    }

    const getPaymentCards = async () => {
        const cards = await paymentCardService.getCards().then(res => {
            if (res.success)
                return res.objects

            addToast(res.errorMessage, {
                appearance: 'info',
                autoDismiss: true,
            });
            return [];
        }, error => {
            console.log(error);
            addToast("No se han podido obtener las tarjetas de pago", {
                appearance: 'error',
                autoDismiss: true,
            });
            return [];
        })

        setPaymentCards(cards);

    }

    const getPromoCode = async (code) => {
        if (isNullOrEmpty(code))
            return null;
        return promoCodeService.getCode(code).then(res => {
            if (res.success)
                return res.object

            return null;
        }, error => {
            console.log(error);
            return null;
        })
    }

    const calculate = async () => {
        const configuration = await getConfiguration();
        if (configuration == null) {
            addToast("No es posible determinar el precio final", {
                appearance: 'error',
                autoDismiss: true,
            });
            return false;
        }

        const serviceCost = parseFloat(configuration.serviceCost);
        const creditCardFee = parseFloat(configuration.creditCardFee);
        const priceService = configuration.pricesByService.filter(x => x.servicioConsular === consularService);

        let price = 0;
        if (priceService.length > 0) {
            price = parseFloat(priceService[0].price);
        }

        let fee = 0;
        if (selectedPayment.type === paymentType.CreditCard)
            fee = price * (creditCardFee / 100) + serviceCost;
        else
            fee = serviceCost;

        fee = Math.round((fee + Number.EPSILON) * 100) / 100;

        let amount = price + fee;

        if (values.applyPromoCode) {
            const promoCode = await getPromoCode(values.promoCode);

            if (promoCode != null) {
                const discount = promoCode.codeType === rateType.Porciento ? promoCode.value * (amount / 100) : promoCode.value;
                amount -= discount;
                amount = Math.round((amount + Number.EPSILON) * 100) / 100;
            }
        }

        const setForm = {
            ...stepPayment,
            values: {
                ...values,
                amount,
                price,
                fee,
            }
        };

        dispatch(setStepPaymentData(setForm));

        return true;
    }

    const handleChange = e => {
        const { name, checked } = e.target;
        dispatch(setStepPaymentData({
            ...stepPayment,
            values: {
                ...values,
                [name]: checked
            }
        }));
    }

    const applyPromoCode = async () => {
        const promoCode = await getPromoCode(values.promoCode);
        let apply = false;
        if (promoCode == null) {
            addToast("El código no puede ser aplicado", {
                appearance: 'info',
                autoDismiss: true,
            });
        }
        else {
            apply = true;
            addToast("El código ha sido aplicado", {
                appearance: 'success',
                autoDismiss: true,
            });
        }

        dispatch(setStepPaymentData({
            ...stepPayment,
            values: {
                ...values,
                'applyPromoCode': apply
            }
        }));

        return true;
    }

    const handleSelectPaymentMethod = (type, id) => {
        dispatch(setStepPaymentData({
            ...stepPayment,
            selectedPayment: {
                type,
                paymentCardId: id
            }
        }));
    }

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} xl={12}>
                    <h5>Detalles del pago:</h5>
                    <p>Costo del trámite: <NumberFormat displayType="text" decimalSeparator='.' value={values.price} prefix='$' /> </p>
                    <p>Costo por Servicio: <NumberFormat displayType="text" decimalSeparator='.' value={values.fee} prefix='$' /> </p>
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                    <Divider variant="middle" />
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                    <p>¿Tiene un código de promoción?</p>
                    <div className="alignPromoCode">
                        <TextField fullWidth label="Introduzca el código" value={values.promoCode} name="promoCode" onChange={handleChange} />
                        <Button size="small" onClick={() => applyPromoCode()}>Aplicar</Button>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                    <Divider variant="middle" />
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                    <p>Total a pagar: <NumberFormat displayType="text" decimalSeparator='.' value={values.amount} prefix='$' /></p>
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                    <Divider variant="middle" />
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                    <h5>Elija el método de pago</h5>
                    <Button variant="text" onClick={() => { navigate("/dashboard/paymentcard") }}>Gestionar Tarjeta</Button>

                    {paymentCards.map((item) => {
                        return (
                            <div className="paymentItem"
                                onClick={() => handleSelectPaymentMethod(paymentType.CreditCard, item.id)}
                            >
                                <div className="paymentItem1">
                                    <CreditCardIcon />
                                    <p className="cardNumber">**** {item.lastFour}</p>
                                </div>
                                {selectedPayment.type === paymentType.CreditCard && selectedPayment.paymentCardId === item.id &&
                                    <div className="paymentItem2">
                                        <CheckCircleIcon />
                                    </div>
                                }

                            </div>
                        )

                    })}

                    <div className="paymentItem" onClick={() => handleSelectPaymentMethod(paymentType.Zelle, "")}>
                        <div className="paymentItem1">
                            <p className="zelle">Zelle</p>
                            <p className="cardNumber">Zelle</p>
                        </div>
                        {selectedPayment.type === paymentType.Zelle &&
                            <div className="paymentItem2">
                                <CheckCircleIcon />
                            </div>
                        }
                    </div>
                </Grid>

                {selectedPayment.type === paymentType.Zelle &&
                    <Grid item xs={12} sm={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Zelle Name"
                            value={values.zelleName}
                            name="zelleName"
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                        />
                    </Grid>}
            </Grid>

            <div className="mt-3">
                <input type="checkbox" id="agree" name="agree" onChange={handleChange} />
                <label htmlFor="agree"> I accept the <Link href='/terms_and_conditions' target="_blank">terms and conditions</Link></label>
            </div>
        </Container>
    )
}
export const servicioConsular = {
    None: 0,
    PrimerVez: 1,
    Prorroga1: 2,
    Prorroga2: 3,
    Renovacion: 4
}

export const paymentType = {
    CreditCard: 0,
    Zelle: 1
}

export const rateType = {
    Fijo: 0,
    Porciento: 1
}

export const categoriaProfesion = {
    None: 0,
    CuentaPropista: 1,
    Deporte: 2,
    Educacion: 3,
    Salud: 4,
    Turismo: 5,
    Otros: 6,
}

export const sex = {
    None: 0,
    Masculino: 1,
    Femenino: 2
}

export const eyeColor = {
    None: 0,
    Claros: 1,
    Negros: 2,
    Pardos: 3
}

export const skinColor = {
    None: 0,
    Blanca: 1,
    Mulata: 2,
    Negra: 3,
    Albina: 4,
    Amarilla: 5
}

export const hairColor = {
    None: 0,
    Canoso: 1,
    Rojo: 2,
    Negro: 3,
    Castano: 4,
    Rubio: 5,
    Otros: 6
}

export const classificationMigration = {
    None: 0,
    AsuntoOficial: 1,
    PermisoEmigracion: 2,
    PRE: 3,
    PSI: 4,
    PVE: 5,
    PVT: 6,
    PSD: 7,
    SalidaIlegal: 8
}

export const schoolLevel = {
    None: 0,
    Analfabeto: 1,
    Primario: 2,
    Secundario: 3,
    PreUniversitario: 4,
    TecnicoMedio: 5,
    Univeritario: 6
}
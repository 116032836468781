import { AgencyId, URL_SERVER } from '../_settings/urlConfig';
import { authHeader, authHeaderTypeJson, handleResponse } from '../_helpers';

export const registerService = {
    register
}


function register(phoneNumber, password, firstName, secondName, firstLastName, secondLastName, email) {
    const model = {
        phoneNumber: phoneNumber,
        password: password,
        firstName: firstName,
        secondName: secondName,
        firstLastName: firstLastName,
        secondLastName: secondLastName,
        email: email
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'x-agency': AgencyId,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(model)
    };
    return fetch(`${URL_SERVER}/api/v1/Register`, requestOptions).then(handleResponse);
}


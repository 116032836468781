import { createSlice } from '@reduxjs/toolkit';
import { State } from 'country-state-city';
import { servicioConsular } from '../_helpers/enums';

const initialValues = {
    consularService: servicioConsular.None,
    currentStep: 0,
    loadingPrimerVez: 1,
    loadingRenovar: 1,
    loadingProrroga1: 1,
    loadingProrroga2: 1,
    stepClientData: {
        states: State.getStatesOfCountry("US"),
        cities: [],
        touched: {},
        errors: [],
        isValid: false,
        values: {
            firstName: '',
            secondName: '',
            firstSurname: '',
            secondSurname: '',
            phone: '',
            address: '',
            state: '',
            city: '',
            zip: '',
            email: '',
            dateBirth: '',
        }
    },
    stepPassportData: {
        touched: {},
        errors: [],
        isValid: false,
        values: {
            passaportNumber: '',
            expirePassport: '',
            reasonNoAvailability: '',
        }
    },
    stepPersonalData: {
        touched: {},
        errors: [],
        isValid: false,
        values: {
            father: '',
            mother: '',
            height: '',
            gender: '',
            eyeColor: '',
            skinColor: '',
            hairColor: '',
        }
    },
    stepGeneralData: {
        provinces: [],
        municipalities: [],
        touched: {},
        errors: [],
        isValid: false,
        values: {
            classificationMigration: '',
            departureDate: '',
            countryOfBirth: '',
            provinceBirth: '',
            municipalityBirth: '',
            checkCountry: '',
        }
    },
    stepLaboralData: {
        touched: {},
        errors: [],
        otherProfession: false,
        isValid: false,
        values: {
            jobCenterName: '',
            profession: '',
            occupation: '',
            categoryProfession: '',
            schoolLevel: '',
        }
    },
    stepReferenceCuba: {
        provinces: [],
        municipalities: [],
        municipalitiesAddress1: [],
        municipalitiesAddress2: [],
        touched: {},
        isValid: false,
        errors: [],
        values: {
            firstNameReference: '',
            secondNameReference: '',
            surnameReference: '',
            secondSurnameReference: '',
            addressReference: '',
            provinceReference: '',
            municipalityReference: '',
            familyRelationship: '',
            addressCuba1: '',
            provinceCuba1: '',
            cityCuba1: '',
            from1: '',
            to1: '',
            addressCuba2: '',
            provinceCuba2: '',
            cityCuba2: '',
            from2: '',
            to2: '',
        }
    },
    stepPhotoSignature: {
        errors: {},
        isValid: false,
        values: {
            passportPhoto: '',
            signature: '',
        }
    },
    stepPaymentData: {
        isValid: false,
        selectedPayment: {
            type: "",
            paymentCardId: "00000000-0000-0000-0000-000000000000"
        },
        values: {
            promoCode: "",
            applyPromoCode: false,
            zelleName: "",
            amount: 0,
            fee: 0,
            price: 0,
            agree: false,
        }
    }
}
export const cretePassportReducer = createSlice({
    name: 'createPassport',
    initialState: {
        ...initialValues
    },
    reducers: {
        setStepClientData: (state, action) => {
            state.stepClientData = action.payload;
        },
        setStepPassportData: (state, action) => {
            state.stepPassportData = action.payload;
        },
        setStepPersonalData: (state, action) => {
            state.stepPersonalData = action.payload;
        },
        setStepGeneralData: (state, action) => {
            state.stepGeneralData = action.payload;
        },
        setStepLaboralData: (state, action) => {
            state.stepLaboralData = action.payload;
        },
        setStepReferenceCuba: (state, action) => {
            state.stepReferenceCuba = action.payload;
        },
        setStepPhotoSignature: (state, action) => {
            state.stepPhotoSignature = action.payload;
        },
        setStepPaymentData: (state, action) => {
            state.stepPaymentData = action.payload;
        },
        setConsularService: (state, action) => {
            state.consularService = action.payload;
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setDefaultValues: (state, action) => (initialValues),
        setValues: (state, action) => (action.payload)
    },
});

export const { setValues, setDefaultValues, setStepPassportData, setConsularService, setStepPaymentData, setCurrentStep, setStepClientData, setStepPersonalData, setStepGeneralData, setStepLaboralData, setStepReferenceCuba, setStepPhotoSignature } = cretePassportReducer.actions;

export default cretePassportReducer.reducer;

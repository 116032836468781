import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import { reset } from 'numeral'
import { useToasts } from 'react-toast-notifications';

// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { registerService } from '../../../_services';

// ----------------------------------------------------------------------
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { addToast } = useToasts()

  const RegisterSchema = Yup.object().shape({
    phoneNumber: Yup.string().matches(phoneRegExp, 'Teléfono no es válido').required('Este campo es obligatorio'),
    firstName: Yup.string().required('Este campo es obligatorio'),
    // firstLastName: Yup.string().required('Este campo es obligatorio'),
    // secondLastName: Yup.string().required('Este campo es obligatorio'),
    email: Yup.string().email('El email no es válido').required('Este campo es obligatorio'),
    password: Yup.string().required('Este campo es obligatorio')
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      password: "",
      firstName: "",
      secondName: "",
      firstLastName: "",
      secondLastName: "",
      email: ""
    },
    validationSchema: RegisterSchema,
    onSubmit: () => submit()
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  const submit = async () => {
    await registerService.register(values.phoneNumber, values.password, values.firstName,
      values.secondName, values.firstLastName, values.secondLastName, values.email).then(res => {
        if (res.success) {
          addToast("Se ha registrado con éxito", {
            appearance: 'success',
            autoDismiss: true,
          });
          navigate("/login");
          return true;
        }
        addToast(res.errorMessage, {
          appearance: 'info',
          autoDismiss: true,
        });
        return false;
      }, error => {
        console.log(error);
        addToast("Ha ocurrido un error", {
          appearance: 'error',
          autoDismiss: true,
        });
      })
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Teléfono"
              {...getFieldProps('phoneNumber')}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
              required
            />

            <TextField
              fullWidth
              autoComplete="email"
              type="email"
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              required
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Primer Nombre"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              required
            />

            <TextField
              fullWidth
              label="Segundo Nombre"
              {...getFieldProps('secondName')}
              error={Boolean(touched.secondName && errors.secondName)}
              helperText={touched.secondName && errors.secondName}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Primer Apellido"
              {...getFieldProps('firstLastName')}
              error={Boolean(touched.firstLastName && errors.firstLastName)}
              helperText={touched.firstLastName && errors.firstLastName}
            />

            <TextField
              fullWidth
              label="Segundo Apellido"
              {...getFieldProps('secondLastName')}
              error={Boolean(touched.secondLastName && errors.secondLastName)}
              helperText={touched.secondLastName && errors.secondLastName}
            />
          </Stack>



          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

import * as React from 'react';
import { Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';

import { setStepGeneralData } from '../../_redux/CreatePassportReducer';
import { discoveryService } from '../../_services'

export default function StepGeneralData() {
    const dispatch = useDispatch()
    const stepReducer = useSelector(state => state.createPassport.stepGeneralData);
    const { provinces, municipalities } = stepReducer;

    const formik = useFormik({
        initialValues: { ...stepReducer.values },
        initialTouched: { ...stepReducer.touched },
        validate: values => {
            const errors = {};
            if (!values.classificationMigration) {
                errors.classificationMigration = 'Este campo es obligatorio'
            }
            if (!values.departureDate) {
                errors.departureDate = 'Este campo es obligatorio'
            }
            if (!values.countryOfBirth && values.checkCountry === '1') {
                errors.countryOfBirth = 'Este campo es obligatorio'
            }
            if (!values.provinceBirth) {
                errors.provinceBirth = 'Este campo es obligatorio'
            }
            if (!values.municipalityBirth) {
                errors.municipalityBirth = 'Este campo es obligatorio'
            }
            if (!values.checkCountry) {
                errors.checkCountry = 'Debe seleccionar una opción'
            }
            return errors;
        }
    })

    React.useEffect(() => {
        dispatch(setStepGeneralData({ ...stepReducer, values: formik.values, errors: formik.errors, touched: formik.touched }))
    }, [formik.values])

    React.useEffect(() => {
        dispatch(setStepGeneralData({ ...stepReducer, isValid: formik.isValid }))
    }, [formik.isValid])

    React.useEffect(() => {
        loadProvinces();
    }, [])

    const loadProvinces = () => {
        discoveryService.getProvinces().then(res => {
            if (res.success) {
                dispatch(setStepGeneralData({ ...stepReducer, provinces: res.objects }))
            }
            return [];
        }).catch(e => {
            console.log(e);
        })
    }

    const loadMunicipalities = async (name) => {
        const province = provinces.filter(x => x.name === name);
        if (province.length > 0)
            dispatch(setStepGeneralData({ ...stepReducer, municipalities: province[0].municipalities }))
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} xl={4}>
                <FormControl fullWidth error={formik.touched.classificationMigration && formik.errors.classificationMigration} >
                    <InputLabel id="classificationMigration">Clasificación Migratoria</InputLabel>
                    <Select
                        labelId="classificationMigration"
                        value={formik.values.classificationMigration}
                        name="classificationMigration"
                        label="Clasificación Migratoria"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        <MenuItem value={1}>Asunto Oficial</MenuItem>
                        <MenuItem value={2}>Permiso Emigracion</MenuItem>
                        <MenuItem value={3}>PRE</MenuItem>
                        <MenuItem value={4}>PSI</MenuItem>
                        <MenuItem value={5}>PVE</MenuItem>
                        <MenuItem value={6}>PVT</MenuItem>
                        <MenuItem value={7}>PSD</MenuItem>
                        <MenuItem value={8}>Salida Ilegal</MenuItem>
                    </Select>
                    {formik.touched.classificationMigration && formik.errors.classificationMigration && <FormHelperText>{formik.errors.classificationMigration}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Fecha de salida"
                        value={formik.values.departureDate}
                        onChange={(value) => {
                            formik.setFieldValue('departureDate', value)
                        }}
                        renderInput={(params) =>
                            <TextField
                                fullWidth
                                {...params}
                                name="departureDate"
                                onBlur={formik.handleBlur}
                                helperText={formik.touched.departureDate && formik.errors.departureDate}
                                error={formik.touched.departureDate && formik.errors.departureDate}
                                required
                            />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} xl={12}>
                <Divider variant="middle" />
            </Grid>
            <Grid item xs={12} sm={12} xl={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Datos de nacimiento</FormLabel>
                    <RadioGroup
                        aria-label="checkCountry"
                        name="checkCountry"
                        defaultChecked={formik.checkCountry}
                        value={formik.checkCountry}
                        onChange={(e) => { formik.handleChange(e); console.log(e.target.value); }}
                    >
                        <FormControlLabel value="0" control={<Radio />} label="Cuba" />
                        <FormControlLabel value="1" control={<Radio />} label="Otro País" />
                    </RadioGroup>
                    {formik.errors.checkCountry && <FormHelperText sx={{ color: 'red' }}>{formik.errors.checkCountry}</FormHelperText>}
                </FormControl>
            </Grid>

            {formik.values.checkCountry === '0' &&
                <Grid item xs={12} sm={12} xl={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} xl={4}>
                            <FormControl fullWidth error={formik.touched.provinceBirth && formik.errors.provinceBirth} >
                                <InputLabel id="eyeColor">Provincia de nacimiento</InputLabel>
                                <Select
                                    labelId="provinceBirth"
                                    value={formik.values.provinceBirth}
                                    label="Provincia de nacimiento"
                                    name="provinceBirth"
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => { formik.handleChange(e); loadMunicipalities(e.target.value); }}
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    {provinces.map((value) => {
                                        return <MenuItem value={value.name}>{value.name}</MenuItem>
                                    })}
                                </Select>
                                {formik.touched.provinceBirth && formik.errors.provinceBirth && <FormHelperText>{formik.errors.provinceBirth}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} xl={4}>
                            <FormControl fullWidth error={formik.touched.municipalityBirth && formik.errors.municipalityBirth} >
                                <InputLabel id="municipalityBirth">Municipio de nacimiento</InputLabel>
                                <Select
                                    labelId="municipalityBirth"
                                    value={formik.values.municipalityBirth}
                                    label="Municipio de nacimiento"
                                    name="municipalityBirth"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    {municipalities.map((value) => {
                                        return <MenuItem value={value.name}>{value.name}</MenuItem>
                                    })}
                                </Select>
                                {formik.touched.municipalityBirth && formik.errors.municipalityBirth && <FormHelperText>{formik.errors.municipalityBirth}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {formik.values.checkCountry === '1' &&
                <Grid item xs={12} sm={12} xl={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} xl={4}>
                            <TextField fullWidth label="País" onBlur={formik.handleBlur} value={formik.values.countryOfBirth} name="countryOfBirth" onChange={formik.handleChange} helperText={formik.touched.countryOfBirth && formik.errors.countryOfBirth} error={formik.touched.countryOfBirth && formik.errors.countryOfBirth} variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={4}>
                            <TextField fullWidth label="Estado" onBlur={formik.handleBlur} value={formik.values.provinceBirth} name="provinceBirth" onChange={formik.handleChange} helperText={formik.touched.provinceBirth && formik.errors.provinceBirth} error={formik.touched.provinceBirth && formik.errors.provinceBirth} variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={4}>
                            <TextField fullWidth label="Ciudad" onBlur={formik.handleBlur} value={formik.values.municipalityBirth} name="municipalityBirth" onChange={formik.handleChange} helperText={formik.touched.municipalityBirth && formik.errors.municipalityBirth} error={formik.touched.municipalityBirth && formik.errors.municipalityBirth} variant="outlined" />
                        </Grid>
                    </Grid>
                </Grid>
            }


        </Grid>
    )
}
import * as React from 'react';
import { Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { setStepReferenceCuba } from '../../_redux/CreatePassportReducer';
import { discoveryService } from '../../_services';
import { servicioConsular } from '../../_helpers/enums';

export default function StepReferenceCuba() {
    const consularService = useSelector(s => s.createPassport.consularService);
    const dispatch = useDispatch()
    const stepReducer = useSelector(state => state.createPassport.stepReferenceCuba);
    const { provinces, municipalities, municipalitiesAddress1, municipalitiesAddress2 } = stepReducer;
    const formik = useFormik({
        initialValues: { ...stepReducer.values },
        initialTouched: { ...stepReducer.touched },
        validate: values => {
            const errors = {};
            if (!values.firstNameReference) {
                errors.firstNameReference = 'Este campo es obligatorio'
            }
            if (!values.surnameReference) {
                errors.surnameReference = 'Este campo es obligatorio'
            }
            if (!values.secondSurnameReference) {
                errors.secondSurnameReference = 'Este campo es obligatorio'
            }
            if (!values.addressReference) {
                errors.addressReference = 'Este campo es obligatorio'
            }
            if (!values.provinceReference) {
                errors.provinceReference = 'Este campo es obligatorio'
            }
            if (!values.municipalityReference) {
                errors.municipalityReference = 'Este campo es obligatorio'
            }
            if (!values.familyRelationship) {
                errors.familyRelationship = 'Este campo es obligatorio'
            }
            if (consularService === servicioConsular.PrimerVez) {
                if (!values.addressCuba1) {
                    errors.addressCuba1 = 'Este campo es obligatorio'
                }
                if (!values.provinceCuba1) {
                    errors.provinceCuba1 = 'Este campo es obligatorio'
                }
                if (!values.cityCuba1) {
                    errors.cityCuba1 = 'Este campo es obligatorio'
                }
                if (!values.from1) {
                    errors.from1 = 'Este campo es obligatorio'
                }
                if (!values.to1) {
                    errors.to1 = 'Este campo es obligatorio'
                }
            }

            return errors;
        }
    })

    React.useEffect(() => {
        dispatch(setStepReferenceCuba({ ...stepReducer, values: formik.values, errors: formik.errors, touched: formik.touched }))
    }, [formik.values])

    React.useEffect(() => {
        dispatch(setStepReferenceCuba({ ...stepReducer, isValid: formik.isValid }))
    }, [formik.isValid])

    React.useEffect(() => {
        loadProvinces();
    }, [])

    const loadProvinces = () => {
        discoveryService.getProvinces().then(res => {
            if (res.success) {
                dispatch(setStepReferenceCuba({ ...stepReducer, provinces: res.objects }))
            }
            return [];
        }).catch(e => {
            console.log(e);
        })
    }

    const loadMunicipalities = async (name) => {
        const province = provinces.filter(x => x.name === name);
        if (province.length > 0)
            dispatch(setStepReferenceCuba({ ...stepReducer, municipalities: province[0].municipalities }))
    }

    const loadMunicipalitiesAddress1 = async (name) => {
        const province = provinces.filter(x => x.name === name);
        if (province.length > 0)
            dispatch(setStepReferenceCuba({ ...stepReducer, municipalitiesAddress1: province[0].municipalities }))
    }
    const loadMunicipalitiesAddress2 = async (name) => {
        const province = provinces.filter(x => x.name === name);
        if (province.length > 0)
            dispatch(setStepReferenceCuba({ ...stepReducer, municipalitiesAddress2: province[0].municipalities }))
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} xl={4}>
                <TextField fullWidth label="Primer Nombre" onBlur={formik.handleBlur} value={formik.values.firstNameReference} name="firstNameReference" onChange={formik.handleChange} helperText={formik.touched.firstNameReference && formik.errors.firstNameReference} error={formik.touched.firstNameReference && formik.errors.firstNameReference} variant="outlined" required />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <TextField fullWidth label="Segundo Nombre" onBlur={formik.handleBlur} value={formik.values.secondNameReference} name="secondNameReference" onChange={formik.handleChange} helperText={formik.touched.secondNameReference && formik.errors.secondNameReference} error={formik.touched.secondNameReference && formik.errors.secondNameReference} variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <TextField fullWidth label="Primer Apellido" onBlur={formik.handleBlur} value={formik.values.surnameReference} name="surnameReference" onChange={formik.handleChange} helperText={formik.touched.surnameReference && formik.errors.surnameReference} error={formik.touched.surnameReference && formik.errors.surnameReference} variant="outlined" required />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <TextField fullWidth label="Segundo Apellido" onBlur={formik.handleBlur} value={formik.values.secondSurnameReference} name="secondSurnameReference" onChange={formik.handleChange} helperText={formik.touched.secondSurnameReference && formik.errors.secondSurnameReference} error={formik.touched.secondSurnameReference && formik.errors.secondSurnameReference} variant="outlined" required />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <TextField fullWidth label="Relación familiar" onBlur={formik.handleBlur} value={formik.values.familyRelationship} name="familyRelationship" onChange={formik.handleChange} helperText={formik.touched.familyRelationship && formik.errors.familyRelationship} error={formik.touched.familyRelationship && formik.errors.familyRelationship} variant="outlined" required />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
                <TextField fullWidth label="Dirección" onBlur={formik.handleBlur} value={formik.values.addressReference} name="addressReference" onChange={formik.handleChange} helperText={formik.touched.addressReference && formik.errors.addressReference} error={formik.touched.addressReference && formik.errors.addressReference} variant="outlined" required />
            </Grid>
            <Grid item xs={6} sm={6} xl={4}>
                <FormControl fullWidth error={formik.touched.provinceReference && formik.errors.provinceReference} >
                    <InputLabel id="provinceReference">Provincia</InputLabel>
                    <Select
                        labelId="provinceReference"
                        value={formik.values.provinceReference}
                        label="Provincia"
                        name="provinceReference"
                        onBlur={formik.handleBlur}
                        onChange={(e) => { formik.handleChange(e); loadMunicipalities(e.target.value); }}

                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {provinces.map((value) => {
                            return <MenuItem value={value.name}>{value.name}</MenuItem>
                        })}
                    </Select>
                    {formik.touched.provinceReference && formik.errors.provinceReference && <FormHelperText>{formik.errors.provinceReference}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} xl={4}>
                <FormControl fullWidth error={formik.touched.municipalityReference && formik.errors.municipalityReference} >
                    <InputLabel id="municipalityReference">Municipio</InputLabel>
                    <Select
                        labelId="municipalityReference"
                        value={formik.values.municipalityReference}
                        label="Municipio"
                        name="municipalityReference"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {municipalities.map((value) => {
                            return <MenuItem value={value.name}>{value.name}</MenuItem>
                        })}
                    </Select>
                    {formik.touched.municipalityReference && formik.errors.municipalityReference && <FormHelperText>{formik.errors.municipalityReference}</FormHelperText>}
                </FormControl>
            </Grid>
            {consularService === servicioConsular.PrimerVez &&
                <Grid item xs={12} sm={12} xl={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} xl={12}>
                            <Divider variant="middle" />
                        </Grid>
                        <Grid item xs={12} sm={12} xl={12}>
                            <h3 className="marginBottom">Direcciones en Cuba</h3>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <TextField fullWidth label="Direccion 1" onBlur={formik.handleBlur} value={formik.values.addressCuba1} name="addressCuba1" onChange={formik.handleChange} helperText={formik.touched.addressCuba1 && formik.errors.addressCuba1} error={formik.touched.addressCuba1 && formik.errors.addressCuba1} variant="outlined" required />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <FormControl fullWidth error={formik.touched.provinceCuba1 && formik.errors.provinceCuba1} >
                                        <InputLabel id="provinceCuba1">Provincia</InputLabel>
                                        <Select
                                            labelId="provinceCuba1"
                                            value={formik.values.provinceCuba1}
                                            label="Provincia"
                                            name="provinceCuba1"
                                            onBlur={formik.handleBlur}
                                            onChange={(e) => { formik.handleChange(e); loadMunicipalitiesAddress1(e.target.value); }}

                                        >
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            {provinces.map((value) => {
                                                return <MenuItem value={value.name}>{value.name}</MenuItem>
                                            })}
                                        </Select>
                                        {formik.touched.provinceCuba1 && formik.errors.provinceCuba1 && <FormHelperText>{formik.errors.provinceCuba1}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <FormControl fullWidth error={formik.touched.cityCuba1 && formik.errors.cityCuba1} >
                                        <InputLabel id="cityCuba1">Municipio</InputLabel>
                                        <Select
                                            labelId="cityCuba1"
                                            value={formik.values.cityCuba1}
                                            label="Municipio"
                                            name="cityCuba1"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        >
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            {municipalitiesAddress1.map((value) => {
                                                return <MenuItem value={value.name}>{value.name}</MenuItem>
                                            })}
                                        </Select>
                                        {formik.touched.cityCuba1 && formik.errors.cityCuba1 && <FormHelperText>{formik.errors.cityCuba1}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} xl={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Desde"
                                            value={formik.values.from1}
                                            views={['year']}
                                            onChange={(value) => {
                                                formik.setFieldValue('from1', value)
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    name="from1"
                                                    onBlur={formik.handleBlur}
                                                    helperText={formik.touched.from1 && formik.errors.from1}
                                                    error={formik.touched.from1 && formik.errors.from1}
                                                    required
                                                />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6} sm={6} xl={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Hasta"
                                            value={formik.values.to1}
                                            views={['year']}
                                            onChange={(value) => {
                                                formik.setFieldValue('to1', value)
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    name="to1"
                                                    onBlur={formik.handleBlur}
                                                    helperText={formik.touched.to1 && formik.errors.to1}
                                                    error={formik.touched.to1 && formik.errors.to1}
                                                    required
                                                />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} xl={12}>
                            <Divider variant="middle" />
                        </Grid>
                        <Grid item xs={12} sm={12} xl={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <TextField fullWidth label="Direccion 2" onBlur={formik.handleBlur} value={formik.values.addressCuba2} name="addressCuba2" onChange={formik.handleChange} helperText={formik.touched.addressCuba2 && formik.errors.addressCuba2} error={formik.touched.addressCuba2 && formik.errors.addressCuba2} variant="outlined" />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <FormControl fullWidth error={formik.touched.provinceCuba2 && formik.errors.provinceCuba2} >
                                        <InputLabel id="provinceCuba2">Provincia</InputLabel>
                                        <Select
                                            labelId="provinceCuba2"
                                            value={formik.values.provinceCuba2}
                                            label="Provincia"
                                            name="provinceCuba2"
                                            onBlur={formik.handleBlur}
                                            onChange={(e) => { formik.handleChange(e); loadMunicipalitiesAddress2(e.target.value); }}

                                        >
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            {provinces.map((value) => {
                                                return <MenuItem value={value.name}>{value.name}</MenuItem>
                                            })}
                                        </Select>
                                        {formik.touched.provinceCuba2 && formik.errors.provinceCuba2 && <FormHelperText>{formik.errors.provinceCuba2}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <FormControl fullWidth error={formik.touched.cityCuba2 && formik.errors.cityCuba2} >
                                        <InputLabel id="cityCuba1">Municipio</InputLabel>
                                        <Select
                                            labelId="cityCuba2"
                                            value={formik.values.cityCuba2}
                                            label="Municipio"
                                            name="cityCuba2"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        >
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            {municipalitiesAddress2.map((value) => {
                                                return <MenuItem value={value.name}>{value.name}</MenuItem>
                                            })}
                                        </Select>
                                        {formik.touched.cityCuba2 && formik.errors.cityCuba2 && <FormHelperText>{formik.errors.cityCuba2}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} xl={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Desde"
                                            value={formik.values.from2}
                                            views={['year']}
                                            onChange={(value) => {
                                                formik.setFieldValue('from2', value)
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    name="from1"
                                                    onBlur={formik.handleBlur}
                                                    helperText={formik.touched.from2 && formik.errors.from2}
                                                    error={formik.touched.from2 && formik.errors.from2}

                                                />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6} sm={6} xl={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Hasta"
                                            value={formik.values.to2}
                                            views={['year']}
                                            onChange={(value) => {
                                                formik.setFieldValue('to2', value)
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    name="to2"
                                                    onBlur={formik.handleBlur}
                                                    helperText={formik.touched.to2 && formik.errors.to2}
                                                    error={formik.touched.to2 && formik.errors.to2}

                                                />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

        </Grid>
    )
}
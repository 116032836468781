import * as React from 'react';
import { FormControl, FormHelperText, Grid, Icon, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useFormik } from 'formik';
import moment from 'moment';
import { Country, State, City } from 'country-state-city';
import { useDispatch, useSelector } from 'react-redux';
import PersonIcon from '@mui/icons-material/Person';

import { setStepClientData } from '../../_redux/CreatePassportReducer';

export default function StepClientData() {
    const dispatch = useDispatch()
    const stepReducer = useSelector(state => state.createPassport.stepClientData);
    const { states, cities } = stepReducer;

    const formik = useFormik({
        initialValues: { ...stepReducer.values },
        initialTouched: { ...stepReducer.touched },
        validate: values => {
            const errors = {};
            if (!values.firstName) {
                errors.firstName = 'Este campo es obligatorio'
            }
            if (!values.firstSurname) {
                errors.firstSurname = 'Este campo es obligatorio'
            }
            if (!values.secondSurname) {
                errors.secondSurname = 'Este campo es obligatorio'
            }
            if (!values.phone) {
                errors.phone = 'Este campo es obligatorio'
            }
            if (!values.address) {
                errors.address = 'Este campo es obligatorio'
            }
            if (!values.state) {
                errors.state = 'Este campo es obligatorio'
            }
            if (!values.city) {
                errors.city = 'Este campo es obligatorio'
            }
            if (!values.zip) {
                errors.zip = 'Este campo es obligatorio'
            }
            if (!values.email) {
                errors.email = 'Este campo es obligatorio';
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = 'Dirección de correo electrónico inválida';
            }

            if (!values.dateBirth) {
                errors.dateBirth = 'Este campo es obligatorio.'
            }
            else {
                const dateBirth = moment(values.dateBirth);
                const now = moment();
                const days = now.diff(dateBirth, 'days');
                if (days <= 0) {
                    errors.dateBirth = 'La fecha de nacimiento no es correcta';
                }
            }

            return errors;

        }
    })

    React.useEffect(() => {
        dispatch(setStepClientData({ ...stepReducer, values: formik.values, errors: formik.errors, touched: formik.touched, isValid: formik.isValid }))
    }, [formik.values])

    React.useEffect(() => {
        dispatch(setStepClientData({ ...stepReducer, isValid: formik.isValid }))
    }, [formik.isValid])

    const loadCities = (stateCode) => {
        const data = City.getCitiesOfState("US", stateCode);
        dispatch(setStepClientData({ ...stepReducer, cities: data }))
    }

    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} xl={4}>
                    <TextField fullWidth label="Primer Nombre" onBlur={formik.handleBlur} value={formik.values.firstName} name="firstName" onChange={formik.handleChange} helperText={formik.touched.firstName && formik.errors.firstName} error={formik.touched.firstName && formik.errors.firstName} variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6} xl={4}>
                    <TextField fullWidth label="Segundo Nombre" onBlur={formik.handleBlur} value={formik.values.secondName} name="secondName" onChange={formik.handleChange} helperText={formik.touched.secondName && formik.errors.secondName} error={formik.touched.secondName && formik.errors.secondName} variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6} xl={4}>
                    <TextField fullWidth label="Primer Apellido" onBlur={formik.handleBlur} value={formik.values.firstSurname} name="firstSurname" onChange={formik.handleChange} helperText={formik.touched.firstSurname && formik.errors.firstSurname} error={formik.touched.firstSurname && formik.errors.firstSurname} variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6} xl={4}>
                    <TextField fullWidth label="Segundo Apellido" onBlur={formik.handleBlur} value={formik.values.secondSurname} name="secondSurname" onChange={formik.handleChange} helperText={formik.touched.secondSurname && formik.errors.secondSurname} error={formik.touched.secondSurname && formik.errors.secondSurname} variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6} xl={4}>
                    <TextField fullWidth label="Teléfono" onBlur={formik.handleBlur} value={formik.values.phone} name="phone" onChange={formik.handleChange} helperText={formik.touched.phone && formik.errors.phone} error={formik.touched.phone && formik.errors.phone} variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6} xl={4}>
                    <TextField fullWidth label="Dirección" onBlur={formik.handleBlur} value={formik.values.address} name="address" onChange={formik.handleChange} helperText={formik.touched.address && formik.errors.address} error={formik.touched.address && formik.errors.address} variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6} xl={4}>
                    <FormControl fullWidth error={formik.touched.state && formik.errors.state} >
                        <InputLabel id="state">Estado</InputLabel>
                        <Select
                            labelId="state"
                            value={formik.values.state}
                            name="state"
                            label="Estado"
                            onBlur={formik.handleBlur}
                            onChange={(e) => { formik.handleChange(e); loadCities(e.target.value) }}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {states.map((value) => {
                                return <MenuItem value={value.isoCode}>{value.name}</MenuItem>
                            })}
                        </Select>
                        {formik.touched.state && formik.errors.state && <FormHelperText>{formik.errors.state}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} xl={4}>
                    <FormControl fullWidth error={formik.touched.city && formik.errors.city} >
                        <InputLabel id="city">Ciudad</InputLabel>
                        <Select
                            labelId="city"
                            value={formik.values.city}
                            name="city"
                            label="Ciudad"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {cities.map((value) => {
                                return <MenuItem selected={value.name === formik.values.city} value={value.name}>{value.name}</MenuItem>
                            })}
                        </Select>
                        {formik.touched.city && formik.errors.city && <FormHelperText>{formik.errors.city}</FormHelperText>}
                    </FormControl>            </Grid>
                <Grid item xs={12} sm={6} xl={4}>
                    <TextField fullWidth label="Código Postal" onBlur={formik.handleBlur} value={formik.values.zip} name="zip" onChange={formik.handleChange} helperText={formik.touched.zip && formik.errors.zip} error={formik.touched.zip && formik.errors.zip} variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6} xl={4}>
                    <TextField fullWidth label="Email" onBlur={formik.handleBlur} value={formik.values.email} name="email" onChange={formik.handleChange} helperText={formik.touched.email && formik.errors.email} error={formik.touched.email && formik.errors.email} variant="outlined" required />
                </Grid>
                <Grid item xs={12} sm={6} xl={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Fecha de nacimiento"
                            value={formik.values.dateBirth}
                            onChange={(value) => {
                                formik.setFieldValue('dateBirth', value)
                            }}
                            renderInput={(params) =>
                                <TextField
                                    fullWidth
                                    {...params}
                                    name="dateBirth"
                                    onBlur={formik.handleBlur}
                                    helperText={formik.touched.dateBirth && formik.errors.dateBirth}
                                    error={formik.touched.dateBirth && formik.errors.dateBirth}
                                    required
                                />}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </Paper>

    )
}